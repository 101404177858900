import fetch from "cross-fetch";
import { ApiConfig } from "@ebs-platform/common/dist/api";
import { FieldsValidationError, NonFieldsError } from "libs/utils";

export interface Organization {
  id: number;
  name: string;
  domain_name: string;
  date_created: string;
  photo: string | null;
  users: number;
}

/**
 * Interface to create organization enities.
 */
export interface OrganizationCreate {
  name: string;
  domain_name: string;
  account: number;
}

export interface OrganizationUpdate {
  name: string;
  photo?: string;
  account: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default (config: ApiConfig) => ({
  /**
   * This will fetch organization for the specified account.
   *
   * @example
   *
   * const orgs = await fetchOrganizations(accId);
   */
  fetchOrganizations: async (acountId: number): Promise<Organization[]> => {
    if (!config.tokens) {
      throw new Error("Session tokens not set");
    }

    const reqInit: RequestInit = {
      method: "GET",
      headers: {
        Authorization: `Token ${config.tokens.access}`
      }
    };
    let response: Organization[] = [];

    const res = await fetch(
      `${config.baseUrl}/organizations/${acountId}/`,
      reqInit
    );

    if (res.ok) {
      response = await res.json();
    }

    return response;
  },

  /**
   * This will create a new Organization object.
   *
   * Note: this function rethrows any error generated during the request.
   */
  create: async (data: OrganizationCreate): Promise<Organization> => {
    if (!config.tokens) {
      throw new Error("Session tokens not set");
    }

    const reqInit: RequestInit = {
      method: "POST",
      headers: {
        Authorization: `Token ${config.tokens.access}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    };

    const res = await fetch(`${config.baseUrl}/organizations/`, reqInit);
    const resData = await res.json();

    if (res.ok) {
      return resData;
    } else if (res.status === 400) {
      const err = Array.isArray(resData.non_field_errors)
        ? new NonFieldsError(resData.non_field_errors[0])
        : new FieldsValidationError(resData, data);
      throw err;
    } else {
      throw resData;
    }
  },

  update: async (
    org: OrganizationUpdate,
    orgId: number
  ): Promise<Organization> => {
    if (!config.tokens) {
      throw new Error("Session tokens not set");
    }

    const reqInit: RequestInit = {
      method: "PATCH",
      headers: {
        Authorization: `Token ${config.tokens.access}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(org)
    };

    const res = await fetch(
      `${config.baseUrl}/organizations/change/${orgId}/`,
      reqInit
    );
    const resData = await res.json();

    if (res.ok) {
      return resData;
    } else if (res.status === 400) {
      const err = Array.isArray(resData.non_field_errors)
        ? new NonFieldsError(resData.non_field_errors[0])
        : new FieldsValidationError(resData, org);
      throw err;
    } else {
      throw resData;
    }
  }
});
